import React from "react";

interface CategoryHealthIconProps {
  className?: string;
}

const CategoryHealthIcon: React.FC<CategoryHealthIconProps> = ({
  className = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <g fill="none">
        <path
          opacity=".2"
          d="M4 20h16V8H4v12zm4-7h3v-3h2v3h3v2h-3v3h-2v-3H8v-2z"
          fill="#111827"
        ></path>
        <path
          d="M20 6h-4V4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM10 4h4v2h-4V4zm10 16H4V8h16v12z"
          fill="#111827"
        ></path>
        <path d="M11 18h2v-3h3v-2h-3v-3h-2v3H8v2h3v3z" fill="#111827"></path>
      </g>
    </svg>
  );
};

export default CategoryHealthIcon;
