import { DishesConfig, LearnConfig } from "@/config/main/tenant";
import { getUrl } from "@/lib/utils";
import { MainSiteConfig } from "@/types";

const mainSiteConfig: MainSiteConfig = {
  url: getUrl(),
  name: process.env.NEXT_PUBLIC_SITE_NAME,
  caption: process.env.NEXT_PUBLIC_SITE_CAPTION,
  app_id: process.env.NEXT_PUBLIC_APP_ID,
  tenants: {
    dishes: DishesConfig,
    learn: LearnConfig,
  },
};
export default mainSiteConfig;
