import { CategoryHomeIcon } from "@/icons/categories";
import { CategoryType } from "@/types";

const mainMobileConfig: CategoryType[] = [
  {
    id: "home",
    title: "Home",
    slug: "/",
    icon: CategoryHomeIcon,
  },
  {
    id: "about",
    title: "About",
    slug: "/about",
    icon: CategoryHomeIcon,
  },
  {
    id: "videos",
    title: "Videos",
    slug: "/videos",
    icon: CategoryHomeIcon,
  },
];

export default mainMobileConfig;
