import mainSiteConfig from "@/config/main/main-site-config";
import { FooterType } from "@/types";

const currentYear = new Date().getFullYear();

const mainFooterConfig: FooterType = {
  categories: [],
  pages: [
    {
      title: "Home",
      slug: "/",
    },
    {
      title: "About",
      slug: "/about",
    },
    {
      title: "Contact",
      slug: "/contact",
    },
    {
      title: "Videos",
      slug: "/videos",
    },
  ],
  legals: [
    {
      title: "Terms",
      slug: "/terms",
    },
    {
      title: "Policy",
      slug: "/policy",
    },
  ],
  copyright: `© ${currentYear} ${mainSiteConfig.name}. All rights reserved.`,
};

export default mainFooterConfig;
