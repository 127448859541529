import { TwitterIcon } from "@/icons/socials";
import { getUrl } from "@/lib/utils";
import { MainWebConfigType } from "@/types";

const LearnConfig: MainWebConfigType = {
  name: process.env.NEXT_PUBLIC_SITE_NAME,
  app_id: process.env.NEXT_PUBLIC_APP_ID,
  url: getUrl(),
  description: "",
  caption: process.env.NEXT_PUBLIC_SITE_CAPTION,
  cloudinary_upload_preset: "iuh72x8t",
  image_hosting: "CLOUDINARY",
  ga: "G-GRNQ5FQYWK",
  clarity: "lkynclb1ed",
  author: {
    name: "LearnCodeProf",
    twitterUrl: "https://twitter.com/LearnCodeProf",
  },
  socials: [
    {
      name: "Twitter",
      url: "https://twitter.com/LearnCodeProf",
      icon: TwitterIcon,
    },
  ],
  color: {
    jewel: {
      "50": "#f2fbf4",
      "100": "#e1f7e5",
      "200": "#c4eecd",
      "300": "#95e0a5",
      "400": "#60c877",
      "500": "#3aad53",
      "600": "#2b8e41",
      "700": "#257036",
      "800": "#246032",
      "900": "#1d4a28",
      "950": "#0b2813",
    },
    loader: "#3aad53",
  },
  icons: {
    icon: [
      { url: "/favicons/learn/favicon-16x16.png" },
      {
        url: "/favicons/learn/favicon-16x16.png",
        sizes: "16x16",
        type: "image/png",
      },
      {
        url: "/favicons/learn/favicon-32x32.png",
        sizes: "32x32",
        type: "image/png",
      },
      {
        url: "/favicons/learn/android-icon-192x192.png",
        sizes: "192x192",
        type: "image/png",
      },
    ],
    shortcut: ["/favicons/learn/favicon-32x32.png"],
    apple: [
      { url: "/favicons/learn/apple-icon.png" },
      {
        url: "/favicons/learn/apple-icon-180x180.png",
        sizes: "180x180",
        type: "image/png",
      },
    ],
    other: [
      {
        rel: "apple-touch-icon-precomposed",
        url: "/favicons/learn/apple-icon-precomposed.png",
      },
    ],
  },
};

export default LearnConfig;
