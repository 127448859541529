const sharedEmptyConfig = {
  title: "Empty",
  description: "No posts to display",
  bookmarkDescription: "No bookmarks to display",
  commentsDescription: "No comments to display",
  contactDescription: "No contact submissions to display",
  carouselDescription: "No carousel items to display",
  categoriesDescription: "No categories to display",
  videosDescription: "No videos to display",
  imagesDescription: "No images to display",
  error: "Something went wrong",
  tryAgain: "Try again",
  sorry: "Sorry",
};

export default sharedEmptyConfig;
