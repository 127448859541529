"use client";

import { CategoryType } from "@/types";
import { Building, Video } from "lucide-react";

const mainSidebarConfig: CategoryType[] = [
  {
    id: "about",
    title: "About",
    slug: "/about",
    icon: Building,
  },
  {
    id: "videos",
    title: "Videos",
    slug: "/videos",
    icon: Video,
  },
];

export default mainSidebarConfig;
