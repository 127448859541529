import { CategoryHomeIcon } from "@/icons/categories";
import { CategoryType } from "@/types";

const mainCategoryConfig: CategoryType[] = [
  {
    id: "home",
    title: "Home",
    slug: "/",
    icon: CategoryHomeIcon,
  }
];

export default mainCategoryConfig;
