import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/icons/socials";
import { getUrl } from "@/lib/utils";
import { MainWebConfigType } from "@/types";

const DishesConfig: MainWebConfigType = {
  name: process.env.NEXT_PUBLIC_SITE_NAME,
  url: getUrl(),
  caption: process.env.NEXT_PUBLIC_SITE_CAPTION,
  description: "",
  app_id: process.env.NEXT_PUBLIC_APP_ID,
  image_hosting: "SUPABASE",
  cloudinary_upload_preset: "",
  ga: "",
  clarity: "",
  author: {
    name: "",
    twitterUrl: "",
  },
  socials: [
    {
      name: "Facebook",
      url: "https://facebook.com",
      icon: FacebookIcon,
    },
    {
      name: "Instagram",
      url: "https://instagram.com",
      icon: InstagramIcon,
    },
    {
      name: "Twitter",
      url: "https://twitter.com",
      icon: TwitterIcon,
    },
    {
      name: "Youtube",
      url: "https://youtube.com",
      icon: YoutubeIcon,
    },
  ],
  color: {
    loblolly: {
      "50": "#f5f7f9",
      "100": "#e7edf2",
      "200": "#d5dfe8",
      "300": "#c0d1dd",
      "400": "#95b0c5",
      "500": "#7b99b6",
      "600": "#6984a7",
      "700": "#5d7498",
      "800": "#50607d",
      "900": "#435065",
      "950": "#2c323f",
    },
    loader: "#7b99b6",
  },
  icons: {
    icon: [
      { url: "/favicons/dishes/favicon-16x16.png" },
      {
        url: "/favicons/dishes/favicon-16x16.png",
        sizes: "16x16",
        type: "image/png",
      },
      {
        url: "/favicons/dishes/favicon-32x32.png",
        sizes: "32x32",
        type: "image/png",
      },
      {
        url: "/favicons/dishes/android-icon-192x192.png",
        sizes: "192x192",
        type: "image/png",
      },
    ],
    shortcut: ["/favicons/dishes/favicon-32x32.png"],
    apple: [
      { url: "/favicons/dishes/apple-icon.png" },
      {
        url: "/favicons/dishes/apple-icon-180x180.png",
        sizes: "180x180",
        type: "image/png",
      },
    ],
    other: [
      {
        rel: "apple-touch-icon-precomposed",
        url: "/favicons/dishes/apple-icon-precomposed.png",
      },
    ],
  },
};

export default DishesConfig;
